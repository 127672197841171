import { defineNuxtPlugin } from '#app/nuxt'
import { LazyActionButton, LazyCardCloud, LazyCardCloudCard, LazyContact, LazyFaqBlock, LazyFaqItem, LazyFeature, LazyGrid, LazyLogoCloud, LazyNavigationDropDown, LazyNavigationLink, LazyPage, LazyPageHeader, LazyPageTitle, LazyTeaser, LazyTypeform, LazyTypeformButton } from '#components'
const lazyGlobalComponents = [
  ["ActionButton", LazyActionButton],
["CardCloud", LazyCardCloud],
["CardCloudCard", LazyCardCloudCard],
["Contact", LazyContact],
["FaqBlock", LazyFaqBlock],
["FaqItem", LazyFaqItem],
["Feature", LazyFeature],
["Grid", LazyGrid],
["LogoCloud", LazyLogoCloud],
["NavigationDropDown", LazyNavigationDropDown],
["NavigationLink", LazyNavigationLink],
["Page", LazyPage],
["PageHeader", LazyPageHeader],
["PageTitle", LazyPageTitle],
["Teaser", LazyTeaser],
["Typeform", LazyTypeform],
["TypeformButton", LazyTypeformButton]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
